
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import DeviceMixin from '~/mixins/device.mixin';
import WidgetMixin from '~/mixins/widget.mixin';
import Banner from '~/models/banner';

@Component({
  name: 'dn-banner-new',
})
export default class BannerNew extends mixins(DeviceMixin, WidgetMixin) {
  @Prop({ type: Object, required: true }) data!: any;
  @Prop({ type: String, default: '' }) redirectUrl!: string;
  @Prop({ type: String, default: '' }) redirectText!: string;
  @Prop({ type: String, default: '' }) dateFrom!: string;
  @Prop({ type: String, default: '' }) dateTo!: string;

  private get sliderItems() {
    let sliders = this.data.bannerFiles || [];
    
    if (this.isMobile) {
      sliders = sliders.filter((item: Banner) => ['mobile', 'mobile_and_desktop'].includes(item.context || ''));
    } else if (this.isTablet) {
      sliders = sliders.filter((item: Banner) => ['tablet', 'mobile_and_desktop'].includes(item.context || ''));
    } else {
      sliders = sliders.filter((item: Banner) => ['desktop', 'mobile_and_desktop'].includes(item.context || ''));
    }

    return sliders;
  }
}

